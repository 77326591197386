$fa-font-path: "../node_modules/@fortawesome/fontawesome-free/webfonts";

@import "./../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "./../node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
@import "./../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "./../node_modules/@fortawesome/fontawesome-free/scss/brands.scss";

.prose p + p {
  margin-top: 1rem;
}

[x-cloak] {
  display: none;
}
